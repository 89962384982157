import React, { useEffect } from "react";
import "./Embers.css";
import "sparkle.js/dist/sparkle.min.js";

export default function Embers({suff}) {
  useEffect(() => {
    document.getElementById(`embers-${suff}`).sparkle({
        position: "beforeend",
        count: 7,
        size: {
          width: "5px",
          height: "5px"
        },
        minAge: 500,
        maxAge: 2000,
        starsource: '⬤'
      });
  }, []);

  return (<div id={`embers-${suff}`} className="embers"></div>);
}
