import React from 'react';
import { Text, Button } from 'theme-ui';
import './PageIntro.css';

export default function PageIntro(props) {
  return (
    <div className='page-intro'>
      <Text>{props.content}</Text>
      <div className='balls'>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Button
        style={{ zIndex: 999, cursor: 'pointer' }}
        variant='underline'
        onClick={() => {
          props.narration.stop();
        }}>
        skip
      </Button>
    </div>
  );
}
