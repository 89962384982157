import React, { useEffect, useState, useRef } from 'react';
import { Text, Flex, Box, Button } from 'theme-ui';
import { Howl } from 'howler';
import { useHistory } from 'react-router-dom';
import PageIntro from './PageIntro';

export default function Intro() {
  const [isLoad2ndIntro, setIsLoad2ndIntro] = useState();
  const narrationRef = useRef();
  const history = useHistory();

  useEffect(() => {
    narrationRef.current = new Howl({
      src: ['narration/vo-intro.mp3'],
      html5: true,
      volume: 1,
      loop: false,
      rate: 1.0,
      onend: function () {
        history.push('/main?view=1');
      },
      onstop: function () {
        history.push('/main?view=1');
      },
      preload: true
    });

    return () => {
        // narrationRef.current.stop();
    };
  }, [history]);

  return (
    <div className='full'>
      <Box
        p={0}
        sx={{
          background:
            'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(10,5,37,1) 9%, rgba(50,73,108,1) 42%, rgba(98,111,131,1) 72%, rgba(203,153,128,1) 100%)',
        }}
        className='full'>
        <Flex
          sx={{
            margin: 30,
            marginBottom: '20px',
            flexDirection: 'column',
            height: '100%',
            textTransform: 'uppercase',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'pre-line',
          }}>
          {isLoad2ndIntro ? (
            <PageIntro
              narration={narrationRef.current}
              content={
                <>
                  We are so glad that <br />
                  you are here.<br />
                  <br />
                  How are you?
                  <br />
                  Are you lonely, or joyful?<br />
                  Or grateful? or Angry?<br />
                  <br />
                  Come as you are.
                </>
              }
            />
          ) : (
            <Box
              sx={{
                maxWidth: '500px',
                textAlign: 'center',
                lineHeight: '1.4em',
              }}>
              <Text>WELCOME, FRIENDS.</Text>
              <Text>IT’S BEEN A VERY HARD YEAR.</Text>
              <Text>
                LET’S TAKE THIS MOMENT
                <br />
                TO GATHER AROUND
                <br />
                A CAMPFIRE, AND WISH
                <br />
                THIS YEAR FAREWELL.
              </Text>
              <Text>
                FIND YOURSELF A PLACE
                <br />
                TO SETTLE IN FOR A BIT.
                <br /> <br />
                ♫ HEADPHONES ♫ AND
                <br /> Desktop Chrome browser
                <br /> highly recommended.
              </Text>
              <Text>(YOU’LL NEED ABOUT 15 MINUTES.)</Text>
              <Button
                variant='underline'
                mr={10}
                onClick={() => {
                  setIsLoad2ndIntro(true);
                  narrationRef.current.play();
                }}>
                Begin
              </Button>
            </Box>
          )}
        </Flex>
      </Box>
    </div>
  );
}
