import React, { createContext, useContext, useReducer } from 'react';
import { useLocation } from 'react-router-dom';

const StoreContext = createContext();
const initialState = { airViews: 0, fireViews: 0, hearthViews: 0 };

const reducer = (state, action) => {
  switch (action.type) {
    case 'viewAir':
      return {
        airViews: state.airViews + 1,
        fireViews: state.fireViews,
        hearthViews: state.hearthViews,
      };
    case 'viewFire':
      return {
        airViews: state.airViews,
        fireViews: state.fireViews + 1,
        hearthViews: state.hearthViews,
      };
    case 'viewHearth':
      return {
        airViews: state.airViews,
        fireViews: state.fireViews,
        hearthViews: state.hearthViews + 1,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

export const useStore = () => useContext(StoreContext);

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
