import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '../controllers/store';
import PageIntro from './PageIntro';
import Messenger from './Messenger';
import { Howl } from 'howler';
import './Air.css';

export default function Air() {
  const [showIntro, setshowIntro] = useState(true);
  const query = useQuery();
  const musicRef = useRef();
  const viewHearth = parseInt(query.get('view'));
  const narration = new Howl({
    src: ['narration/vo-stars.mp3'],
    html5: true,
    volume: 1,
    loop: false,
    rate: 1.0,
    onend: function () {
      setshowIntro(false);
    },
    onstop: function () {
      setshowIntro(false);
    },
    preload: true
  });
  useEffect(() => {
    if (viewHearth > 2) {
      setshowIntro(false);
      narration.stop();
    } else {
      narration.play();
      setshowIntro(true);
    }

    musicRef.current = new Howl({
      src: ['music/hg-bg-stars.mp3'],
      html5: true,
      volume: 0.4,
      loop: true,
      preload: true
    });

    musicRef.current.play();
    console.log('music air playing');

    return () => {
      musicRef.current.once('fade', () => {
        musicRef.current.stop();
      });
      musicRef.current.fade(musicRef.current.volume(), 0, 1000);
    };
  }, []);

  return (
    <div className='air'>
      {showIntro ? (
        <PageIntro
          narration={narration}
          content={
            <>
              It's the dawn of a new year. <br />
              <br />
              The future has not
              <br />
              yet been written.
              <br />
              <br />
              it is our time to do the
              <br />
              writing, invite the
              <br />
              mystery of the unknown,
              <br />
              manifest our hopes.
              <br />
              <br />
              the sky is big, and has room
              <br />
              for all of our dreams.
              <br />
              <br />
              what is your hope
              <br />
              for 2021?
            </>
          }
        />
      ) : (
        <Messenger room='hello' viewHearth={viewHearth} />
      )}
    </div>
  );
}
