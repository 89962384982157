import React, { useState } from 'react';
import { Card, Text, Textarea, Button, Flex, Box } from 'theme-ui';
import { addMsg } from '../controllers/firebase';
import { Howl } from 'howler';

export default function AddMsgs(props) {
  const [msg, setMsg] = useState('');

  const submitMsg = () => {
    if (msg !== '') {
      addMsg('rooms', props.room, msg).then(() => {
        setMsg('');
        props.setIsAddMsg(false);
      });
    }
    playEffect();
  };

  const getDefaultPrompt = () => {
    if (props.room === 'hello') {
      return 'Hello...';
    }
    return 'Goodbye...';
  };

  const getTitle = () => {
    if (props.room === 'hello') {
      return 'ADD HOPE';
    }
    return 'ADD FUEL';
  };

  const playEffect = () => {
    if (props.room === 'hello') {
      props.effectAir.play();
      console.log('play air submit');
    } else {
      props.effectFire.play();
      // props.musicDrums.volume(0);
      props.drumsMusic();
      console.log(props.drumsMusic);
      console.log('play fire submit');
    }
  };

  return (
    <Flex
      sx={{
        margin: 'auto',
        justifySelf: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Text variant='addMsg'>{getTitle()}</Text>
      <Card variant='airInput'>
        <Textarea
          defaultValue={getDefaultPrompt()}
          rows={8}
          onChange={(e) => setMsg(e.target.value)}
          maxLength={75}
          sx={{
            textTransform: 'uppercase',
            outline: 'none',
            resize: 'none',
          }}
        />
      </Card>
      <Box>
        <Button style={{cursor: 'pointer' }} variant='underline' mr={10} onClick={submitMsg}>
          SUBMIT
        </Button>
        <Button
          style={{cursor: 'pointer' }}
          variant='underline'
          ml={10}
          onClick={() => {
            props.setIsAddMsg(false);
          }}>
          CLOSE
        </Button>
      </Box>
    </Flex>
  );
}
