import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDPwI25myF1kDVcNbe4_qJCy_zOyaE3IAI",
  authDomain: "hello-goodbye-ebf4c.firebaseapp.com",
  projectId: "hello-goodbye-ebf4c",
  storageBucket: "hello-goodbye-ebf4c.appspot.com",
  messagingSenderId: "256547748755",
  appId: "1:256547748755:web:b9498174978bb5bf63c666",
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseDb = firebase.firestore();

export const getMsgList = (collection, doc) => {
  return firebaseDb.collection(collection).doc(doc).collection("msgs").get();
};

export const addMsg = (collection, doc, msg) => {
  return firebaseDb.collection(collection).doc(doc).collection("msgs").add({
    msg: msg,
    created: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

export const addSiteView = () => {
  return firebaseDb
    .collection("site")
    .doc("views")
    .update("count", firebase.firestore.FieldValue.increment(1));
};

export const getSiteViewCount = () => {
  return firebaseDb.collection("site").doc("views").get();
};

export const getSiteUserCount = () => {
  return firebaseDb.collection("site").doc("users").get();
};

export const getCurrentUser = () => firebase.auth().currentUser;

export const getIdTokenResult = (b) =>
  firebase.auth().currentUser.getIdTokenResult(b);

export const getConfig = () => {
  
  return {
    providers: {
      googleProvider: new firebase.auth.GoogleAuthProvider(),
    },
    firebaseAppAuth: firebaseApp.auth(),
  };
};
