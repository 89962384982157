//theme.js
export default {
  fonts: {
    body: '"ApercuPro", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: '"CanelaLight", serif',
    // message: '"Canela-Regular", sans-serif',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 300,
  },
  lineHeights: {
    body: '168.2%',
    heading: '168.2%',
  },
  letterSpacings: {
    body: '0.1em',
    heading: '0.1em',
  },
  colors: {
    text: '#e5e5e5',
    background: '#011729',
    primary: '#07c',
    secondary: '#30c',
    muted: '#f6f6f6',
  },
  text: {
    default: {
      fontFamily: 'body',
      marginBottom: '20px',
      fontSize: 14,
      lineHeight: 1.9,
      letterSpacing: 2,
      padding: 15,
      paddingTop: 0,
      paddingBottom: 0,
      animation: 'myfade 0.75s',
      animationTimingFunction: 'ease-in',
    },
    heading: {
      fontFamily: 'heading',
      fontSize: 28,
      lineHeight: '28px',
      marginBottom: '10%',
      letterSpacing: 0,
      textTransform: 'capitalize',
    },
    addMsg: {
      fontFamily: 'body',
      fontSize: 12,
      lineHeight: 1.5,
      paddingBottom: 12,
      letterSpacing: 2,
    },
    visited: {
      fontFamily: 'body',
      fontSize: 8,
      letterSpacing: 2,
    },
  },
  links: {
    fontFamily: 'body',
    fontWeight: 'bold',
    outline: 'none',
    colors: '#fff',
    textDecoration: 'none',
    '&:visited': {
      color: '#fff',
    },
  },
  images: {
    outline: 'none',
    textTransform: 'uppercase',
    chevron: {
      outline: 'none',
      width: '52px',
      height: '52px',
    },
    close: {
      outline: 'none',
      width: '42px',
      height: '42px',
    },
  },
  buttons: {
    cursor: 'pointer',
    outline: 'none',
    icon: {
      outline: 'none',
      width: '62px',
      height: '62px',
    },
    underline: {
      fontFamily: 'ApercuPro',
      color: 'text',
      bg: 'transparent',
      fontSize: '12px',
      border: 'none',
      borderRadius: '0px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      padding: '0px 2px 4px 2px',
      borderBottom: '1.5px solid #e2e2e2',
      '&:active': {
        bg: 'transparent',
      },
    },
  },
  cards: {
    air: {
      // width: '80%',
      paddingBotton: 0,
      borderRadius: '0px',
      border: '3x solid',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
    airInput: {
      width: '160%',
      marginBottom: 20,
      borderRadius: 0,
      border: '3x solid',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
  },
};
