import React, { useEffect, useState } from 'react';
import { Text, Button, Flex, Box, IconButton, Image } from 'theme-ui';
import { useHistory } from 'react-router-dom';
import { getMsgList } from '../controllers/firebase';
import chevronUp from '../images/chevron-up.svg';
import chevronDown from '../images/chevron-down.svg';
import arrowLeft from '../images/arrow-left.svg';
import arrowRight from '../images/arrow-right.svg';
import './ViewMsgs.css';

export default function ViewMsgs(props) {
  const [msgList, setMsgList] = useState([]);
  const [msgIndex, setMsgIndex] = useState(0);
  const history = useHistory();
  useEffect(() => {
    fetchMsgs();
  }, []);

  const fetchMsgs = async () => {
    let results = await getMsgList('rooms', props.room).then(function (querySnapshot) {
      let list = [];
      querySnapshot.forEach(function (doc) {
        list.push(
          <Text
            className='messages'
            variant='heading'
            key={doc.data().created}
            sx={{
              alignSelf: 'center',
              textAlign: 'center',
              // textTransform: 'uppercase',
              wordWrap: 'anywhere',
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              maxHeight: '60%',
            }}>
            {doc.data().msg}
          </Text>
        );
      });
      return list;
    });
    shuffleArray(results);
    setMsgList(results);
  };

  const incMsg = () => {
    let i = msgIndex + 1;
    i = i % msgList.length;
    setMsgIndex(i);
  };

  const decMsg = () => {
    let i = msgIndex - 1;
    if (i < 0) {
      i = msgList.length - 1;
    }
    setMsgIndex(i);
  };

  //Durstenfeld shuffle
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const backbutton = () => {
    history.push(`/main?view=${props.viewHearth + 1}`); //return to main
  };

  const renderGoodbyeChevron = () => {
    if (props.room === 'goodbye') {
      return (
        <IconButton onClick={backbutton}>
          <Image variant='chevron' src={chevronUp} />
        </IconButton>
      );
    }
    return (
      <Box
        sx={{
          minWidth: 350,
          minHeight: 62,
        }}></Box>
    );
  };

  const renderHelloChevron = () => {
    if (props.room === 'hello') {
      return (
        <IconButton className='animated bounce' style={{ cursor: 'pointer' }} onClick={backbutton}>
          <Image variant='chevron' src={chevronDown} />
        </IconButton>
      );
    }
  };

  const renderAddMsgText = () => {
    if (props.room === 'hello') {
      return 'ADD HOPE';
    }
    return 'ADD FUEL';
  };

  const renderMsgs = () => {
    if (msgList.length > 0) {
      return msgList[msgIndex];
    }
  };

  return (
    <>
      {renderGoodbyeChevron()}
      <Flex className='scroll-holder'>
        <IconButton style={{ cursor: 'pointer' }} onClick={decMsg} className='scroll-button'>
          <Image src={arrowLeft} />
        </IconButton>
        <Flex
          sx={{
            minHeight: 250,
            width: 250,
            margin: 15,
            justifyContent: 'center',
            textTransform: 'uppercase',
          }}>
          {renderMsgs()}
        </Flex>
        <IconButton style={{ cursor: 'pointer' }} onClick={incMsg} className='scroll-button'>
          <Image src={arrowRight} />
        </IconButton>
      </Flex>

      <Flex>
        <Button style={{ cursor: 'pointer' }} variant='underline' onClick={() => props.setIsAddMsg(true)}>
          {renderAddMsgText()}
        </Button>
      </Flex>
      {renderHelloChevron()}
    </>
  );
}
