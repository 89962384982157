import React, { useState, useEffect, useRef } from 'react';
import { Flex } from 'theme-ui';
import ViewMsgs from './ViewMsgs';
import ScrollMsgs from './ScrollMsgs';
import AddMsgs from './AddMsgs';
import { Howl } from 'howler';

export default function Messenger(props) {
  const [isAddMsg, setIsAddMsg] = useState(false);
  const musicDrumsRef = useRef();
  const effectAir = new Howl({
    src: ['effects/fx-star.mp3'],
    html5: true,
    volume: 1,
    loop: false,
    rate: 1.0,
    preload: true
  });

  const effectFire = new Howl({
    src: ['effects/fx-submit-button-purify-gong.mp3'],
    html5: true,
    volume: 1,
    loop: false,
    rate: 1.0,
    preload: true
  });

  const fadeDrums = () => {
    musicDrumsRef.current.stop();
    musicDrumsRef.current.volume(0);
    musicDrumsRef.current.play();
    musicDrumsRef.current.fade(0, 0.1, 20000);
  };

  useEffect(() => {
    if (props.room === 'goodbye') {
      musicDrumsRef.current = new Howl({
        src: ['music/hg-bg-fire-drums.mp3'],
        html5: true,
        volume: 0.2,
        loop: true,
        rate: 1.0,
        preload: true
      });
      musicDrumsRef.current.play();
    }

    return () => {
      if (props.room === 'goodbye') {
        musicDrumsRef.current.stop();
      }
    };
  }, []);

  const renderMsgView = () => {
    if (isAddMsg) {
      return (
        <AddMsgs
          room={props.room}
          effectAir={effectAir}
          effectFire={effectFire}
          drumsMusic={fadeDrums}
          setIsAddMsg={setIsAddMsg}
        />
      );
    } else if (props.room === 'hello') {
      return <ViewMsgs room={props.room} setIsAddMsg={setIsAddMsg} viewHearth={props.viewHearth} />;
    } else if (props.room === 'goodbye') {
      return <ScrollMsgs room={props.room} setIsAddMsg={setIsAddMsg} viewHearth={props.viewHearth} />;
    }
  };

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        animationTimingFunction: 'ease-in',
        animation: 'myfade 0.5s',
      }}>
      {renderMsgView()}
    </Flex>
  );
}
