import React, { useEffect } from 'react';
import Snap from 'snapsvg-cjs';
import { TweenMax, Power2, Linear } from 'gsap';
import './FireFlame.css';

export default function FireFlame({vb, suff}) {
  useEffect(() => {
    //TweenMax.lagSmoothing(0);
    let snapSVG = Snap(`.flame-${suff}`);
    let stageManager = new StageManager(snapSVG);
    stageManager.init();
    const makeFlame = () => {

      // prevent fire from growing when the browser is kept open for a very long time
      if (Object.keys(stageManager.fire).length < 10) {
        stageManager.addFlame();
      }

      setTimeout(makeFlame, Math.random() * 60 + 0.1);
    };
    makeFlame();
  }, []);

  return (
    <div className='container'>
      <svg
        className={`flame-${suff}`}
        xmlns='http://www.w3.org/2000/svg'
        id='svg'
        width='100%'
        height='100%'
        preserveAspectRatio='xMidYMid meet'
        viewBox={vb}></svg>
    </div>
  );
}

class Flame {
  group;
  startPoint;
  endPoint;
  startThickness;
  endThickness;
  guidePosition;
  frequency;
  amplitude;
  height;
  endHeight;
  y;
  particle;
  body;
  id;

  constructor(svg, start, end, width, particle) {
    this.id = String(Math.round(Math.random() * 999999999999999));
    this.group = svg.group();
    this.group.addClass('flame');
    this.startPoint = start;
    this.endPoint = end;
    this.startThickness = width + Math.round(Math.random() * 200);
    this.endThickness = 10 + Math.round(Math.random() * 10);
    this.guidePosition = Math.random() * 100;
    this.frequency = (0.02 + Math.random() * 0.008)*0.4; // more chill
    this.amplitude = 40 + Math.random() * 40;
    this.height = 500;
    this.endHeight = 400 + Math.round(Math.random() * 400);
    this.y = Math.random() * 100;
    this.particle = particle;

    let colors = ['#fa8f02', '#EB621E', '#E53421', '#CC2E28'];

    this.body = this.group.path().attr({
      fill: this.particle ? '#fa8f02' : colors[Math.floor(Math.random() * colors.length)],
      opacity: this.particle ? 1 : 0.8,
      stroke: 'none',
    });

    this.updateGuide();
  }

  remove() {
    this.group.remove();
  }

  updateGuide() {
    this.guide = [];
    let height = this.startPoint.y - this.endPoint.y;
    let widthChange = this.startPoint.x - this.endPoint.x;
    let y = this.startPoint.y;

    while (y-- >= this.endPoint.y) {
      var x = this.startPoint.x + (widthChange - (widthChange / height) * y);
      var wave = Math.sin(y * this.frequency + this.guidePosition);
      this.guide.push({ y: y, x: x + ((wave * this.amplitude) / 2 + this.amplitude / 2) });
    }
  }

  start(onComplete) {
    if (this.particle)
      TweenMax.to(this, 0.2 + Math.random(), {
        y: this.guide.length,
        height: this.endHeight,
        ease: Linear.ease,
        onComplete: onComplete,
        onCompleteParams: [this],
      });
    else
      TweenMax.to(this, 0.5 + Math.random(), {
        y: this.guide.length,
        height: this.endHeight,
        ease: Power2.easeIn,
        onComplete: onComplete,
        onCompleteParams: [this],
      });
  }

  getPointAlongGuide(y, offsetXPercentage) {
    if (this.guide.length) {
      if (y >= this.guide.length) y = this.guide.length - 1;
      if (y < 0) y = 0;

      var thicknessDifference = this.endThickness - this.startThickness;
      var percentageAlongGuide = (y / this.guide.length) * 100;
      var thickness = this.startThickness + (thicknessDifference / 100) * percentageAlongGuide;
      var xOffset = (thickness / 2 / 100) * offsetXPercentage;
      return { x: this.guide[y].x + xOffset, y: this.guide[y].y };
    }
    return { x: 0, y: 0 };
  }

  drawPath(pathPoints) {
    var points = [];

    for (var i = 0; i < pathPoints.length; i++) {
      var subPoints = [];
      for (var j = 0; j < pathPoints[i].points.length / 2; j++) {
        var p = pathPoints[i].points.slice(j * 2, j * 2 + 2);
        var point = this.getPointAlongGuide(Math.round(p[1]), p[0]);
        subPoints.push(point.x);
        subPoints.push(point.y);
      }
      points.push(pathPoints[i].type + subPoints.join(' '));
    }

    return points.join(' ') + 'Z';
  }

  draw() {
    if (this.height > 0) {
      var y = Math.round(this.y);
      var height = Math.round(this.height);
      var heightChunks = height / 6;
      var body = this.particle
        ? [
            { type: 'M', points: [0, y] },
            {
              type: 'L',
              points: [10, y - heightChunks * 0.2, 20, y - heightChunks * 0.4, 30, y - heightChunks * 0.6],
            },
          ]
        : [
            { type: 'M', points: [0, y] },
            {
              type: 'L',
              points: [
                10,
                y - heightChunks * 0.2,
                20,
                y - heightChunks * 0.4,
                30,
                y - heightChunks * 0.6,
                40,
                y - heightChunks * 0.8,
                50,
                y - heightChunks * 1,
                60,
                y - heightChunks * 1.2,
                70,
                y - heightChunks * 1.4,
                80,
                y - heightChunks * 1.6,
                90,
                y - heightChunks * 1.8,
                90,
                y - heightChunks * 2,
                90,
                y - heightChunks * 2.2,
                80,
                y - heightChunks * 2.4,
                70,
                y - heightChunks * 2.6,
                60,
                y - heightChunks * 2.8,
                50,
                y - heightChunks * 3,
                40,
                y - heightChunks * 3.1,
                30,
                y - heightChunks * 3.2,
                20,
                y - heightChunks * 3.3,
                10,
                y - heightChunks * 3.4,
                0,
                y - heightChunks * 3.5,
                -10,
                y - heightChunks * 3.4,
                -20,
                y - heightChunks * 3.3,
                -30,
                y - heightChunks * 3.2,
                -40,
                y - heightChunks * 3.1,
                -50,
                y - heightChunks * 3,
                -60,
                y - heightChunks * 2.8,
                -70,
                y - heightChunks * 2.6,
                -80,
                y - heightChunks * 2.4,
                -90,
                y - heightChunks * 2.2,
                -90,
                y - heightChunks * 2,
                -90,
                y - heightChunks * 1.8,
                -80,
                y - heightChunks * 1.6,
                -70,
                y - heightChunks * 1.4,
                -60,
                y - heightChunks * 1.2,
                -50,
                y - heightChunks * 1,
                -40,
                y - heightChunks * 0.8,
                -30,
                y - heightChunks * 0.6,
                -20,
                y - heightChunks * 0.4,
                -10,
                y - heightChunks * 0.2,
                0,
                y - heightChunks * 0,
              ],
            },
          ];

      this.body.attr({ d: this.drawPath(body) });
    }
  }
}

class StageManager {
  svg;
  size;
  fire;

  constructor(svg) {
    this.svg = svg;
    this.fire = {};
    this.size = { width: 0, height: 0 };
  }

  init() {
    window.addEventListener('resize', () => this.onResize(), true);
    this.onResize();
    this.tick();
  }

  onResize() {
    this.size.width = 450;
    this.size.height = 500;

    //this.svg.attr('width', '100%').attr('height', '100%');
  }

  addFlame() {
    let particle = Math.random() > 0.8;
    let start = { x: this.size.width / 2, y: this.size.height - 40 };
    let end = {
      x: this.size.width / 4 + Math.random() * (this.size.width / 2),
      y: particle ? -20 : this.size.height / 3,
    };
    let width = this.size.width / 4;
    let flame = new Flame(this.svg, start, end, width, particle);
    flame.start((flame) => this.removeFlame(flame));
    this.fire[flame.id] = flame;
  }

  removeFlame(flame) {
    delete this.fire[flame.id];
    flame.remove();
    flame = null;
  }

  tick() {
    for (let i in this.fire) {
      this.fire[i].draw();
    }

    requestAnimationFrame(() => this.tick());
  }
}
