import React, { useEffect, useState, useRef } from 'react';
import { IconButton, Image, Flex, Text, Box } from 'theme-ui';
import { Link } from 'react-router-dom';
import FireFlame from './FireFlame';
import Embers from './Embers';
import PageIntro from './PageIntro';
import { useQuery } from '../controllers/store';
import { addSiteView, getSiteUserCount } from '../controllers/firebase';
import { Howl, Howler } from 'howler';
import chevronUp from '../images/chevron-up.svg';
import chevronDown from '../images/chevron-down.svg';
import './Main.css';

export default function Main() {
  const narrationRef = useRef();
  const query = useQuery();
  const viewHearth = parseInt(query.get('view'));
  const [showIntro, setshowIntro] = useState(false);
  const [viewCount, setViewCount] = useState(0);
  const [introIdx, setIntroIdx] = useState(0);
  const introText = [
    <>
      TAKE A DEEP BREATH OF
      <br />
      THE COOL NIGHT AIR.
      <br />
      <br />
      This fire calls to you.
      <br />
      Feel the heat pull you closer
      <br />
      as the flames dance.
    </>,
    <>
      take a deep inhale.
      <br />
      and...
      <br />
      let
      <br />
      it
      <br />
      all
      <br />
      go.
      <br />
      <br />
      <br />
      <br />
      <br />
      now, look up to the sky.
    </>,
    <>
      thank you for gathering,
      <br />
      and being a part of our <br />
      collective catharsis and <br />
      our collective dreams. <br />
      <br />
      <br />
      goodbye 2020. hello 2021.
    </>,
  ];

  useEffect(() => {
    let introIdx = 0;

    let narration = new Howl({
      src: ['narration/narration-hearth.webm', 'narration/narration-hearth.mp3'],
      sprite: {
        hearth1: [0, 15030.79365079365],
        hearth2: [17000, 19256.68934240363],
        hearth3: [38000, 17593.764172335596],
      },
      html5: true,
      volume: 1,
      loop: false,
      rate: 1.0,
      onend: function () {
        console.log('Finished Narration!');
        setshowIntro(false);
      },
      onstop: function () {
        setshowIntro(false);
      },
      preload: true,
    });
    narrationRef.current = narration;
    

    console.log(Howler);

    let music = new Howl({
      src: ['music/music-hearth.webm,', 'music/music-hearth.mp3'],
      sprite: {
        hearth1: [0, 106057.14285714287],
        hearth2: [108000, 104071.83673469388],
        hearth3: [214000, 104071.83673469388],
      },
      html5: true,
      volume: 1.0,
      loop: true,
      preload: true
    });

    // first time in main
    if (viewHearth === 1) {
      addSiteView();
      narration.play('hearth1');
      music.play('hearth1');
      setshowIntro(true);
      setIntroIdx(0);

      // post-fire back to main
    } else if (viewHearth === 2) {
      addSiteView();
      narration.play('hearth2');
      music.play('hearth2');
      setshowIntro(true);
      setIntroIdx(1);

      // post-air back to main aka CLOSING
    } else if (viewHearth === 3) {
      addSiteView();
      narration.play('hearth3');
      music.play('hearth3');
      setshowIntro(true);
      setIntroIdx(2);

      // post-closing
    } else {
      setshowIntro(false);
      music.play('hearth3');
    }
    fetchViewCount();

    return () => {
      // if (state.hearthViews < 3) {
      //   narration.stop();
      // }

      music.once('fade', () => {
        music.stop();
      });
      music.fade(music.volume(), 0, 1000);
    };
  }, []);

  const fetchViewCount = async () => {
    let results = await getSiteUserCount()
      .then(function (doc) {
        console.log('site user count:', doc.data().count);
        setViewCount(doc.data().count);
      })
      .catch(function (error) {
        console.log('Error getting site user count:', error);
      });
  };

  return (
    <div className='main'>
      <div className='fire-holder'>
        {introIdx != 0 ? <Embers suff='main' /> : ''}
        <FireFlame vb='0 0 500 425' suff='main' />
      </div>
      {showIntro ? (
        <>
          <PageIntro narration={narrationRef.current} content={introText[introIdx]} />
        </>
      ) : (
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
          }}>
          <div style={{ zIndex: 999 }}>
            {viewHearth > 1 ? (
              <Link to={'/air?view=' + viewHearth}>
                <IconButton className='animated bounce' style={{ zIndex: 999, cursor: 'pointer' }}>
                  <Image variant='chevron' src={chevronUp} />
                </IconButton>
              </Link>
            ) : (
              ''
            )}
          </div>
          <Box
            sx={{
              textAlign: 'center',
              height: '70%',
            }}>
            <Text
              sx={{
                textAlign: 'center',
                height: '10%',
                fontSize: '11px',
              }}>
              {viewCount} OTHERS HAVE COME HERE BEFORE YOU.
            </Text>
          </Box>
          <div>
            {viewHearth === 2 ? (
              ''
            ) : (
              <Link to={'/fire?view=' + viewHearth}>
                <IconButton
                  className='animated bounce'
                  style={{ position: 'relative', zIndex: 999, cursor: 'pointer' }}>
                  <Image
                    sx={{
                      zIndex: '999',
                      cursor: 'pointer',
                    }}
                    variant='chevron'
                    src={chevronDown}
                  />
                </IconButton>
              </Link>
            )}
          </div>
        </Flex>
      )}
    </div>
  );
}
