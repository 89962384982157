import React, { useEffect, useState } from "react";
import withFirebaseAuth from "react-with-firebase-auth";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { ThemeProvider, Text } from "theme-ui";
import theme from "./theme";
import { StoreProvider } from "./controllers/store";
import {getConfig, getCurrentUser, getIdTokenResult} from "./controllers/firebase";
import Air from "./Components/Air";
import Fire from "./Components/Fire";
import Main from "./Components/Main";
import Intro from "./Components/Intro";
import "./App.css";

function App(props) {
  const { user, signOut, signInWithGoogle } = props;

  const [isAuthorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (user) {
      getAuthorized();
    }
  });

  const getAuthorized = async () => {
    if (getCurrentUser()) {
      const idTokenResult = await getIdTokenResult(true);
      if (idTokenResult.claims.authorized) {
        console.log('authed');
        setAuthorized(true);
      } else {
        setTimeout(getAuthorized, 4000);
      }
    }
  };

  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <div className="App">
          {user && isAuthorized ? (
            <Router>
              <>
                <AnimatedSwitch
                  atEnter={{ opacity: 0 }}
                  atLeave={{ opacity: 0 }}
                  atActive={{ opacity: 1 }}
                  className="switch-wrapper"
                >
                  <Route path="/air" className="full">
                    <Air />
                  </Route>
                  <Route path="/fire" className="full">
                    <Fire />
                  </Route>
                  <Route path="/main" className="full">
                    <Main />
                  </Route>
                  <Route exact path="/" className="full">
                    <Intro />
                  </Route>
                </AnimatedSwitch>
              </>
            </Router>
          ) : (
            <div className="login-wrapper">
            <Text className="login-wrapper-heading" variant='heading'>/ Hello / Good/Bye / Hello / Good/Bye / Hello / Good/Bye / Hello /</Text>
            <button
              className="login-button"
              variant='heading'
              onClick={signInWithGoogle}
            > Login with IDEO gmail</button>
            </div>
          )}
        </div>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default withFirebaseAuth(getConfig())(App);

