import React, { useState, useEffect } from 'react';
import PageIntro from './PageIntro';
import Messenger from './Messenger';
import { useQuery } from '../controllers/store';
import { Howl } from 'howler';
import './Fire.css';
import FireFlame from './FireFlame';
import Embers from './Embers';

export default function Fire(props) {
  const [showIntro, setshowIntro] = useState(true);

  const query = useQuery();
  const viewHearth = parseInt(query.get('view'));
  const narration = new Howl({
    src: ['narration/vo-fire.mp3'],
    html5: true,
    volume: 0.75,
    loop: false,
    rate: 1.0,
    onend: function () {
      setshowIntro(false);
    },
    onstop: function () {
      setshowIntro(false);
    },
    preload: true
  });

  useEffect(() => {
    if (viewHearth > 1) {
      setshowIntro(false);
      narration.stop();
    } else {
      narration.play();
      setshowIntro(true);
    }

    let music = new Howl({
      src: ['music/hg-bg-fire.mp3'],
      html5: true,
      volume: 0.3,
      loop: true,
      preload: true
    });

    music.play();

    return () => {
      music.once('fade', () => {
        music.stop();
      });
      music.fade(music.volume(), 0, 1000);
    };
  }, []);

  return (
    <div className='fire'>
      {showIntro ? (
        <PageIntro
          narration={narration}
          content={
            <>
              It’s time for us to
              <br />
              collectively say A goodbye,
              <br />
              and maybe also a “f” you,
              <br />
              to 2020.
              <br />
              <br />
              What do you want to
              <br />
              release, burn, relinquish
              <br /> to the flames?
            </>
          }
        />
      ) : (
        <Messenger room={'goodbye'} viewHearth={viewHearth} />
      )}
      <div className='fire-holder'>
        <Embers suff='fire' />
        <FireFlame vb='200 0 100 425' suff='fire' />
      </div>
      <div className='fire-bg'></div>
    </div>
  );
}
