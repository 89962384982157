import React, { useEffect, useState, useRef } from 'react';
import { Text, Button, Flex, Box, IconButton, Image } from 'theme-ui';
import { useHistory } from 'react-router-dom';
import { getMsgList } from '../controllers/firebase';
import chevronUp from '../images/chevron-up.svg';
import arrowUp from '../images/arrow-up.svg';
import './ScrollMsgs.css';
import { Howl } from 'howler';
import { useInterval } from '../controllers/utils';

export default function ScrollMsgs(props) {
  const [msgList, setMsgList] = useState([]);
  const [renderList, setRenderList] = useState([]);
  const [msgIndex, setMsgIndex] = useState(0);
  const history = useHistory();

  useInterval(() => {
    incMsg();
  }, 3000);

  useEffect(() => {
    fetchMsgs();

    return () => {};
  }, []);

  const fetchMsgs = async () => {
    let results = await getMsgList('rooms', props.room).then(function (querySnapshot) {
      let list = [];
      querySnapshot.forEach(function (doc) {
        list.push(
          <Text
            className='messages'
            variant='heading'
            //key={doc.data().created}
            sx={{
              alignSelf: 'center',
              textAlign: 'center',
              textTransform: 'capitalize',
              wordWrap: 'anywhere',
              whiteSpace: 'pre-wrap',
              marginBottom: '10%',
            }}>
            {doc.data().msg}
          </Text>
        );
      });

      return list;
    });
    shuffleArray(results);
    if (results.length > 2) {
      setRenderList([results[0], results[1], results[2]]);
      let trimResults = results.slice(2);
      setMsgList(trimResults);
    } else {
      setMsgList(results);
    }
  };

  const incMsg = () => {
    let i = msgIndex + 1;
    i = i % msgList.length;
    let tempList = renderList.slice(0);
    tempList.push(msgList[i]);
    if (tempList.length > 3) {
      tempList.shift();
    }
    setRenderList(tempList);
    setMsgIndex(i);
  };

  //Durstenfeld shuffle
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const backbutton = () => {
    history.push(`/main?view=${props.viewHearth + 1}`); //return to main
  };

  const renderGoodbyeChevron = () => {
    if (props.room === 'goodbye') {
      return (
        <IconButton
          className='animated bounce'
          style={{ zIndex: 999, cursor: 'pointer' }}
          sx={{
            // position: 'absolute',
            marginTop: '20px',
          }}
          onClick={backbutton}>
          <Image variant='chevron' src={chevronUp} />
        </IconButton>
      );
    }
    return (
      <Box
        sx={{
          minWidth: 350,
          minHeight: 62,
        }}></Box>
    );
  };

  const renderMsgs = () => {
    return renderList.map((item, i) => (
      <div key={i} style={{ minHeight: '50px', opacity: (i + 1) / renderList.length }}>
        {item}
      </div>
    ));
  };

  return (
    <>
      {renderGoodbyeChevron()}
      <Flex
        sx={{
          margin: 24,
        }}>
        <Flex
          sx={{
            minHeight: 250,
            width: 250,
            margin: 15,
            // justifyContent: 'center',
            textTransform: 'uppercase',
            flexDirection: 'column',
          }}>
          {renderMsgs()}
        </Flex>
      </Flex>
      <Flex>
        <Button
          style={{ zIndex: 999, cursor: 'pointer' }}
          sx={{
            marginBottom: '100px',
          }}
          variant='underline'
          mr={2}
          onClick={() => props.setIsAddMsg(true)}>
          ADD FUEL
        </Button>
      </Flex>
    </>
  );
}
